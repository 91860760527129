import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LocalizedLink as Link, useLocalization } from 'gatsby-theme-i18n';
import { useLocation } from "@reach/router"

import styles from '../../styles/common/header.module.css';

import localized from '../../../i18n/localized.json';

function Header() {
  const { locale, config } = useLocalization();
  const [langOpen, handleLangOpen] = useState(false);
  const [bookCategoriesOpen, handleBookCategoriesOpen] = useState(false);
  const location = useLocation();

  const {
    site: { siteMetadata: { name } }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
          }
        }
      }
    `
  );

  const switchLang = () => {
    if (!langOpen) {
      handleBookCategoriesOpen(false);
    }
    handleLangOpen(!langOpen);
  }

  const switchBookCategories = () => {
    if (!bookCategoriesOpen) {
      handleLangOpen(false);
    }
    handleBookCategoriesOpen(!bookCategoriesOpen);
  }

  const locales = config.map(lang => lang.code);
  const unlocalizedPath = locales.includes(location.pathname.slice(1, 3)) ? location.pathname.slice(3) : location.pathname;

  return (
    <header className={styles.header}>
      <div className={styles.headerWrapper}>
        <div className={styles.headerName}>
          <Link to={`/`} activeClassName={styles.activeLink}>{name}</Link>
        </div>
        <nav>
          <div className={styles.dropdown} onClick={switchLang}>
            <span>{localized[locale].header.language}</span>
            <div className={langOpen ? styles.dropdownOpen : styles.dropdownClose}>
              {config.map(lang => (
                <Link to={unlocalizedPath} language={lang.code} key={lang.code} className={lang.code === locale ? styles.activeLanguage : ''}>{lang.localName}</Link>
              ))}
            </div>
          </div>
          <div className={styles.dropdown} onClick={switchBookCategories}>
            <span className={location.pathname.includes('books') ? styles.activeDropdown : ''}>{localized[locale].book.title}</span>
            <div className={bookCategoriesOpen ? styles.dropdownOpen : styles.dropdownClose}>
              <Link to="/books" activeClassName={styles.activeLink}>{localized[locale].book.all}</Link>
              <Link to="/books/to-read" activeClassName={styles.activeLink}>{localized[locale].book.toRead}</Link>
              <Link to="/books/currently-reading" activeClassName={styles.activeLink}>{localized[locale].book.currentlyReading}</Link>
              <Link to="/books/read" activeClassName={styles.activeLink}>{localized[locale].book.read}</Link>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import styles from '../../styles/common/footer.module.css';

function Footer() {
  const { site: { siteMetadata: seo } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
          }
        }
      }
    `
  );

  return (
    <footer className={styles.footer}>&copy; {seo.name} {new Date().getFullYear()}.</footer>
  );
}

export default Footer;

import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/common/layout';
import SEO from '../components/common/seo';
import SocialLink from '../components/home/socialLink';
import ProjectLink from '../components/home/projectLink';

import styles from '../styles/home/home.module.css';

function Home({ data, pageContext }) {
  return (
    <Layout>
      <SEO />
      <div className={styles.homeWrapper}>
        <div className={styles.aboutMe}>
          <div className={styles.wrapperCenter}>
              <img className={styles.profilePic} src={data.avatar.fixed.src} alt={`${data.site.siteMetadata.name} avatar`} />
              <h1>{data.site.siteMetadata.name}</h1>
              <div className={styles.subheader}>{data.site.siteMetadata.pageSubtitle}</div>

              <div className={styles.divider} />

              {data.site.siteMetadata.personDescription.map((desc, index) => (
                <div className={styles.descriptionLine} key={index}>
                  {desc[pageContext.locale]}
                </div>
              ))}
          </div>
        </div>
        <div className={styles.socialLinks}>
          <div className={styles.links}>
              <h2>around the web:</h2>
              {data.site.siteMetadata.socialMedia.map(socialMedia => (
                <SocialLink key={socialMedia.url} data={socialMedia} />
              ))}
          </div>

          <div className={styles.projects}>
              <h2>projects</h2>
              {data.site.siteMetadata.projects.map((project, index) => (
                <ProjectLink key={index} project={project} />
              ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    avatar: imageSharp(fixed: {originalName: {eq: "avatar.jpg"}}) {
      fixed(height: 240, width: 240, quality: 90) {
        src
      }
    }
    site {
      siteMetadata {
        backgroundColor
        name
        pageSubtitle
        personDescription {
          pl
          en
        }
        projects {
          displayName
          languageIconName
          sourceIconName
          url
        }
        socialMedia {
          displayName
          iconName
          url
        }
      }
    }
  }
`;

export default Home;
